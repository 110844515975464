import React from 'react'
// import App from '../App.css'

export default function InputField(props) {
    // console.log("Input_value",props);
  return (
    <div >
      <input  className={`input-componentsField ${props?.customerInputCss} `}
      //  ref={props?.ref}  
       {...props.field} placeholder={props.placeholder}  type={props.type}
       style={props?.fieldState&&props?.fieldState?.error ? { borderColor: 'red' } : {}}

       />
    </div>
  )
}
