import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/global.css'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter, Route, Routes,useParams } from 'react-router-dom';

import store from "./redux/store";
import Sidebars from './component/Sidebar';
import BillingTable from './pages/BillingTable';
import BillingForm from './pages/BillingForm';
import BillingView from './component/BillingView';
import BillingInvoice from './pages/BillingInvoice';
import ProductInvoice from './pages/ProductInvoice';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useSelector } from 'react-redux';
export default function Home() {
    const queryClient = new QueryClient()
    const count = useSelector((state) => state.user);
    console.log("count_login", count&&count?.login);
    const [data, setData] = useState();
    useEffect(()=>{
      setData(localStorage.getItem('login'))
    },[localStorage.getItem('login') ])
    const value = sessionStorage.getItem('login');
    const { slug } = useParams();
    console.log('LoginPAge',slug);
  return (
    <BrowserRouter>
     <QueryClientProvider client={queryClient}>

 

    {value?
    <>
{

}
    <Sidebars />
      <React.StrictMode>
      <Routes>

        <Route path="/" element={<BillingView />} />
        <Route path="/Billing/BillingForm" element={<BillingForm />} />
        <Route path="/BillingForm" element={<BillingForm/>}/>
        <Route path="/Billing/BillingTable" element={<BillingTable />} />
        <Route path="/ProductView/Invoice" element={<ProductInvoice />} />
        {/* <Route path="/Purchase/PurchaseForm" element={<PurchaseForm/>}/>
        <Route path="/Purchase/PurchaseTable" element={<PurchaseTable />} /> */}
      </Routes>
    </React.StrictMode>
    </>
      :<>
      <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />} />
        </Routes>
        </React.StrictMode>
      </>
    }

     </QueryClientProvider>
  </BrowserRouter>
  )
}
