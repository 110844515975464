import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "loginUser",
  initialState: {
    user: null,
    customerData:null,
    salesInvoice:null
  },
  reducers: {
    login: (state,action) => {
      state.user = action.payload;
      console.log('start',state.value);
    },
    logout: (state,action) => {
      state.user = null;
      console.log('start_action',action);
      // navigator()
    },
    customer: (state,action) => {
      state.customerData = action.payload;
      console.log('start',state.value);
    },
    sales: (state,action) => {
      state.salesInvoice = action.payload;
      console.log('salesInvoice',state.value);
    },
  },
});

export const { login,logout,customer } = counterSlice.actions;

export const selectUser = (state) => state.user.user
export const customerUser = (state) => state.customerData.customerData
export const sales = (state) => state.salesInvoice.salesInvoice

export default counterSlice.reducer
// export default handleCart;
