import {configureStore} from '@reduxjs/toolkit';
import rootReducers from './reducer/handleCart';
const store = configureStore({
    reducer: rootReducers,
    user:rootReducers,
    customerData:rootReducers

})

export default store;
