import React, { Component, Fragment, useEffect, useState } from "react";
// import ProductHeader from "./ProductHeader";
import companyLogo from "../assets/image/2k_logo.svg";
import Goback from "../assets/image/GoBack.svg";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import moment from "moment/moment";

export default function InvoiceProduct(props) {
  const componentRef = useRef();
  const ShareRef = useRef();
  const [sessionValue, setSessionValue] = useState([]);

  useEffect(() => {
    const savedObjectString = sessionStorage.getItem("myValue");
    if (savedObjectString) {
      setSessionValue(JSON.parse(savedObjectString));
      console.log("savedObjectString", sessionValue);
    }
  }, []);
  const tableHeader = [
    "Product No",
    // "Name",

    // "Number",
    "Product Name",
    "Unit Price",
    "Discount Price",
    "Quantity",

    "Mode",
    "Status",
    "Total Price",
  ];
  const tableData = [
    {
      SNo: sessionValue?.id,
      customerName: sessionValue?.customerName,
      // customerAddress: sessionValue?.customerAddress,
      contactNumber: sessionValue?.contactNumber,
      // pincode: sessionValue?.pincode,
      transactionMode: sessionValue?.transactionMode,
      transactionStatus: sessionValue?.transactionStatus,
      productName: sessionValue?.productName,
      unitPrice: sessionValue?.unitPrice,
      discountPrice: sessionValue?.discountPrice,
      quantity: sessionValue?.quantity,
      actualPrice: sessionValue?.actualPrice,
    },
  ];
  const [isRemove, setIsRemove] = useState(false);
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "TAX INVOICE",
    // onBeforePrint:()=>alert('printing is going on'),
    // onAfterPrint:()=>alert('printing success')
  });

  const handleShare = () => {
    const shareInvoice = componentRef.current;

    html2canvas(shareInvoice).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Invoice.pdf");
      console.log('imgData',imgData);
      console.log('pdf',pdf);
      console.log('pdfWidth',pdfWidth);
      console.log('pdfHeight',pdfHeight);
      console.log('imgWidth',imgWidth);
      console.log('imgHeight',imgHeight);
      console.log('ratio',ratio);
      console.log('imgX',imgX);
      console.log('imgY',imgY);
    });
  };
  useEffect(() => {
    if (isRemove === true) {
      sessionStorage.removeItem("myValue");
      navigate("/Billing/BillingTable");
    }
  }, [isRemove]);

  return (
    <>
      <div>
        <div className="invoice-container">
          <div className="billing_invoice_header">
            <div>
              <img
                src={Goback}
                alt="go back"
                onClick={() => navigate("/Billing/BillingTable")}
              />
            </div>

            <div className="invoice_head_section">
              <div>
                <h3 className="billing_invoice_heading">Sales Invoice</h3>
              </div>

              <div>
                <input
                  type="submit"
                  value="Print"
                  className="billing_print_btn"
                  onClick={() => (handlePrint(), setIsRemove(!isRemove))}
                />
                <input
                  type="submit"
                  value="Download"
                  className="billing_print_btn"
                  onClick={() => (handleShare(), setIsRemove(!isRemove))}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="invoice-main-block" ref={componentRef}>
              <div className="TaxInvoice-name">
                <i>TAX INVOICE</i>
              </div>
              <div className="companyName-headerContainer">
                <div>
                  <img
                    className="company-logo"
                    src={companyLogo}
                    alt="companyLogo"
                  />
                </div>
                <div className="company-nameAddress">
                  <div>
                    <span className="company-name"> 2K Inventry </span> <br />
                    <span className="company-address">
                      No 19 Pillaiyar Kovil street Rajan Nagar <br />
                      Kelambakkam 603103 <br />
                      9876543210,Web Address
                    </span>
                  </div>
                  <div className="company-dateInvoiceNo">
                    <span className="company-date">DATE :</span>{" "}
                    {moment.utc().format("DD-MM-YYYY")} <br />
                    <span className="company-date">INVOICE NO :</span>{" "}
                    <span>IVN</span> {sessionValue?.id}
                  </div>
                </div>
              </div>
              <div className="company-fromTo-container">
                <div className="company-from-To">
                  <span className="company-from"> From :</span> <br />
                  No 19 Pillaiyar Kovil street Rajan Nagar <br />
                  Kelambakkam 603103 <br />
                  9876543210,Web Address
                </div>
                <div className="company-from-To">
                  <span className="company-from"> To :</span> <br />
                  {sessionValue?.customerName} <br />
                  {sessionValue?.customerAddress} <br />
                  {sessionValue?.pincode} <br />
                  {sessionValue?.contactNumber},Web Address
                </div>
              </div>
              <div>
                <TableContainer className="invoice-tableContainer">
                  <Table>
                    <TableHead className="Invoice-tableHeard">
                      <TableRow className="Invoice-tableRow">
                        {tableHeader.map((title) => (
                          <TableCell
                            style={{ padding: "12px" }}
                            className="invoice-tableName"
                          >
                            {title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="Invoice-dataTableBody">
                      {tableData.map((data, i) => {
                        return (
                          <Fragment key={i}>
                            <>
                              <TableRow>
                                <TableCell className=" Invoice-dataName">
                                  {data.SNo}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.productName}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.unitPrice}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.quantity}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.discountPrice}
                                </TableCell>
                                {/* <TableCell className=" Invoice-dataName">
                                {data.customerName}
                              </TableCell> */}
                                {/* <TableCell className=" Invoice-dataName">
                                {data.customerAddress}
                              </TableCell> */}
                                {/* <TableCell className=" Invoice-dataName">
                                {data.contactNumber}
                              </TableCell> */}
                                {/* <TableCell className=" Invoice-dataName">
                                {data.pincode}
                              </TableCell> */}
                                <TableCell className=" Invoice-dataName">
                                  {data.transactionMode}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.transactionStatus}
                                </TableCell>
                                <TableCell className=" Invoice-dataName">
                                  {data.actualPrice}
                                </TableCell>
                              </TableRow>
                            </>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="invoice-totalContainer">
                <div className="total-text">
                  <div className="total-textColum">SUB-TOTAL</div>
                  <div className="total-textColum">C-GST 18% </div>
                  <div className="total-textColum">S-GST 18% </div>
                  <div className="total-textColum">TOTAL</div>
                </div>
                <div className="total-number">
                  <div className="total-numberColum">
                    {sessionValue?.actualPrice}
                  </div>
                  <div className="total-numberColum">--</div>
                  <div className="total-numberColum">-- </div>
                  <div className="total-numberColum">
                    {sessionValue?.actualPrice}
                  </div>
                </div>
              </div>
              <div className="invoice-thanksText">
                <i>THANK YOU FOR YOUR BUSINESS</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
