import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { addCart } from "../redux/action";
import { login } from "../redux/reducer/handleCart";
import axios from "axios";
import { useMutation } from "react-query";
import stock from "../assets/image/stock.png";
import companyLogo from "../assets/image/2k_logo.svg";
export default function SignIn({ setValue }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState("password");
  const [error, setError] = useState();

  const { state } = useLocation();

  const passwordType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    // First argument: function that makes the actual request
    // Second argument (optional): Config object

    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/companies/login`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (data) => {
        // Handle successful mutation
        sessionStorage.setItem("login", true);
        sessionStorage.setItem(
          "user",
          data && data?.data && data?.data?.company && data?.data?.company?.role
        );
        sessionStorage.setItem(
          "data",
          data &&
            data?.data &&
            data?.data?.company &&
            data?.data?.company?.companyLogo
        );
        dispatch(
          login({
            data: data && data?.data && data?.data?.company,
            login: true,
          })
        );
        // navigator(`${data&&data?.data&&data?.data?.company&&data?.data?.company?.role=='superAdmin'?'/':'/UserRegisterTable'}`)
        // window.location.reload();
      },
      onError: (error) => {
        // Handle error
        setError(error);
        console.log(
          "Mutation failed:",
          error,
          error?.response &&
            error?.response?.data &&
            error?.response?.data?.message
        );
      },
    }
  );
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const handleClick = (data) => {
    mutate(data);
  };
  useEffect(() => {
    localStorage.setItem("login", true);
  }, []);
  const [data, setData] = useState(localStorage.getItem("login") || "");
  console.log("LOGINPage", isError, error);
  return (
    <div className="SignIn-mainContainer">
      <div className="signIn-inner-container">
        <div className="signIn-header">
          <div>
            {" "}
            <img
              className="signIn-companyLogo"
              src={companyLogo}
              alt="companyLogo"
            />{" "}
          </div>
          <div className="signIn-companyName">2K Inventory</div>
        </div>
        <div className="signIn-input-container">
          <form
            onSubmit={handleSubmit((data) => {
              handleClick(data);
            })}
            className="singIn-input-form"
          >
            <div style={{ marginBottom: "25px" }}>
              <label className="signIn-labelName">Username:</label>
              <Controller
                control={control}
                name="username"
                render={(field) => (
                  <InputField {...field} name="--model" type="text" />
                )}
              />
            </div>
            <div>
              <label className="signIn-labelName">Password:</label>
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField {...field} name="--model" type={type} />
                )}
              />
            </div>
            <div onClick={passwordType} style={{ marginBottom: "30px" }}>
              <input type="checkBox" checked={type == "text" ? "true" : ""} />{" "}
              Show Password
            </div>
            <div>
              <button className="signIn-button" type="submit">
                {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Login"}
              </button>
            </div>
            <div> 
              {" "}
              {isError && (
                <p className="signIn_error">
                  {error &&
                    error?.response &&
                    error?.response?.data &&
                    error?.response?.data?.message}
                </p>
              )}{" "}
            </div>
          </form>
        </div>
      </div>
      <div>
        <img src={stock} alt="stockImage" style={{ width: "90%", height: "100%" }} />
      </div>
    </div>
  );
}
// const mapDispatchToProps = (dispatch) => ({
//   setValue: (value) => dispatch({ type: "SET_VALUE", payload: value }),
// });
// connect(null, mapDispatchToProps);
