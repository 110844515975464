import React from "react";

const BillingAddProductField = (props) => {
  return (
    <div>
      <input
        type={props.type}
        placeholder={props.placeholder}
        {...props.field}
        className="billingAddProductField"
        list={props.list}
      ></input>
    </div>
  );
};

export default BillingAddProductField;
